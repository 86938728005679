import React, { useEffect, useState } from "react";
import moment from "moment";
import { FormattedMessage } from "react-intl";
import { Link, useParams } from "react-router-dom";
import { Card, CardBody, Col, Row, Spinner } from "reactstrap";

import SubmissionReview from "../../components/SubmissionReview";
import { IntlService, TaskService } from "../../wasfaty/services";
import "./style.scss";

function ScheduleSelfAssessmentDetails({ item }) {
  if (!item) return null;
  return (
    <>
      {!item && task_id && <SubmissionReview task={item.task} />}

      <Row id="AuditDetails">
        <Col>
          {!item && (
            <div
              style={{
                position: "absolute",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                zIndex: 10,
                width: "100%",
              }}
            >
              <Spinner
                color="primary"
                style={{
                  height: 50,
                  width: 50,
                }}
              />
            </div>
          )}

          <nav
            className="navbar navbar-expand-md mb-1 py-1"
            style={{ backgroundColor: "#fff" }}
          >
            <div className="container-fluid">
              <Link
                className="navbar-brand"
                to="#"
                style={{ color: "#525866", fontWeight: "500" }}
              >
                {IntlService.m(item ? "Review " : " Details")}
              </Link>
            </div>
          </nav>
          {item && (
            <>
              <Card style={{ borderRadius: "0px", marginBottom: "5px" }}>
                <CardBody>
                  <Row>
                    <Col md="4" sm="12">
                      <p>
                        <FormattedMessage
                          id="Form Name"
                          defaultMessage={"Form Name"}
                        />
                      </p>
                      <h4>{item?.form_name?.name}</h4>
                    </Col>
                    <Col md="4" sm="12">
                      <p>
                        <FormattedMessage
                          id="Region"
                          defaultMessage={"Region"}
                        />
                      </p>
                      {item?.region?.map((obj, i) => (
                        <h4 key={i}>{obj.name}</h4>
                      ))}
                    </Col>
                    <Col md="4" sm="12">
                      <p>
                        <FormattedMessage
                          id="Cluster"
                          defaultMessage={"Cluster"}
                        />
                      </p>
                      {item?.cluster?.map((obj, i) => (
                        <h4 key={i}>{obj.name}</h4>
                      ))}
                    </Col>

                    <Col md="4" sm="12">
                      <p>
                        <FormattedMessage
                          id="Start Date"
                          defaultMessage={"Start Date"}
                        />
                      </p>
                      <h4>{moment(item?.start_date).format("YYYY-MM-DD")}</h4>
                    </Col>
                    <Col md="4" sm="12">
                      <p>
                        <FormattedMessage
                          id="End Date"
                          defaultMessage={"End Date"}
                        />
                      </p>
                      <h4>{moment(item?.end_date).format("YYYY-MM-DD")}</h4>
                    </Col>
                  </Row>
                  {item?.site?.length > 0 && (
                    <>
                      <hr />
                      <p>
                        <FormattedMessage id="Sites" defaultMessage={"Sites"} />{" "}
                        <span style={{ fontSize: 18 }}>
                          <b>({item?.site?.length})</b>
                        </span>
                      </p>
                      <div className="d-flex" style={{ flexWrap: "wrap" }}>
                        {item?.site?.map((obj, i) => (
                          <div
                            key={i}
                            style={{
                              paddingInline: 10,
                              marginBlock: 4,
                              borderRadius: 999,
                              border: "1px solid #E2E4E9",
                              marginRight: 5,
                            }}
                          >
                            <h4>
                              {i + 1} - {obj.name_ar || obj.name}
                            </h4>
                          </div>
                        ))}
                      </div>
                    </>
                  )}
                </CardBody>
              </Card>
            </>
          )}
        </Col>
      </Row>
    </>
  );
}

export default ScheduleSelfAssessmentDetails;
