import { v4 as uuidv4 } from "uuid";
import { encryptPassword } from "../../../utility/securePass/encryptPassword";

export const getNonce = () => {
  const nonce = uuidv4();
  const nonceEnc = encryptPassword(nonce);
  return {
    "X-Custom-Header": nonce,
    // "X-Custom-Header-Encrypt": nonce,
    // "X-Request-Nonce-encrypt": JSON.stringify(nonceEnc),
  };
};
