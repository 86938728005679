import { observer } from "mobx-react";
import { Button, Input, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { IntlService } from "../../../views/wasfaty/services";

const RejectModel = observer(
  ({ rejectModel, toggleRejectModel, inputValue, onChange, SubmitAction }) => {
    return (
      <Modal
        isOpen={rejectModel}
        toggle={toggleRejectModel}
        centered
        style={{ "--modal-max-width": "30vw" }}
      >
        <ModalHeader>{IntlService.m("Reject Reason")}</ModalHeader>
        <ModalBody className="d-flex justify-content-center">
          <Input type="textarea" value={inputValue} onChange={onChange} />
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            onClick={() => {
              if (inputValue != "") {
                SubmitAction();
                toggleRejectModel();
              } else {
                toast.error(IntlService.m("Please fill the Reject Reason!"));
              }
            }}
          >
            {IntlService.m("Submit")}
          </Button>
          <Button color="secondary" onClick={toggleRejectModel}>
            {IntlService.m("Cancel")}
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
);

export default RejectModel;