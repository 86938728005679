import { Singleton } from "../Resource";
import get from "lodash/get";
import { getUserData } from "@utils";
import ModuleService from "./ModuleService";
const roles = [
  { id: 1, value: "admin", label: "Compliance Admin" },
  {
    id: 2,
    value: "regional representatives",
    label: "Regional Representatives",
  },
  {
    id: 3,
    value: "supervisor",
    label: "Supervisor",
  },
  { id: 4, value: "agent", label: "Inspector" },
  { id: 5, value: "pharmacist", label: "Pharmacist" },
  { id: 6, value: "facility manager", label: "Facility Manager" },

  { id: 6, value: "facility", label: "Facility" },
  { id: 7, value: "cluster manager", label: "Cluster Manager" },
  { id: 8, value: "expert", label: "Expert" },
  {
    id: 9,
    value: "regulator representative",
    label: "Regulator Representative",
  },
  { id: 10, value: "hold_co", label: "Hold Co" },
  {
    id: 11,
    value: "ticketing_representative",
    label: "Ticketing Representative",
  },

  {
    id: 12,
    value: "Medical Case Department",
    label: "Medical Case Department",
  },

  { id: 13, value: "hold_co", label: "Hold Co" },
  { id: 14, value: "compliance_legal_admin", label: "Compliance Legal Admin" },
  { id: 12, value: "sof admin", label: "SOF Admin" },
];

const sofRoles = [
  { id: 1, value: "sof facility", label: "SOF Facility" },
  { id: 2, value: "facility manager", label: "Facility Manager" },
  { id: 3, value: "sof cluster", label: "SOF Cluster" },
  { id: 4, value: "sof hold co", label: "SOF Hold Co" },
];

export const getRoleLabel = (role = "") => {
  switch (role) {
    case "agent":
      return "Inspector";
    case "supervisor":
      return "Supervisor";
    case "admin":
      return "Compliance Admin";
    case "regional representatives":
      return "Regional Representatives";
    case "facility":
      return "Facility";

    case "facility manager":
      return "Facility Manager";

    case "hold_co":
      return "Hold Co";

    case "regulator representative":
      return "Regulator Representative";
    case "ticketing_representative":
      return "Ticketing Representative";
    case "compliance_legal_admin":
      return "Compliance Legal Admin";

    case "sof facility":
      return "SOF Facility";
    case "sof cluster":
      return "SOF Cluster";
    case "sof hold co":
      return "SOF Hold Co";

    case "sof admin":
      return "SOF Admin";

    default:
      return role;
  }
};

class AuthClass {
  unprotectedModule = [];

  constructor() {}

  get role() {
    return get(this.user, "type")?.toLowerCase();
  }

  get currentRoleLevel() {
    return get(
      this.roles.filter((role) => role.value === this.role),
      "0.id"
    );
  }

  get isAdmin() {
    return this.role === "admin";
  }

  get isSOFAdmin() {
    return this.role === "sof admin";
  }
  get isSOFFacility() {
    return this.role === "sof facility";
  }
  get isSOFCuster() {
    return this.role === "sof cluster";
  }
  get isSOFHoldCo() {
    return this.role === "sof hold co";
  }

  get isHoldCo() {
    return this.role === "hold_co";
  }

  get isTicketingRepresentative() {
    return this.role === "ticketing_representative";
  }

  get isSupervisor() {
    return this.role === "supervisor";
  }

  get isNupcoAdmin() {
    return this.role === "nupco_admin";
  }

  get isRegionalRepresentatives() {
    return this.role === "regional representatives";
  }

  get isClusterManager() {
    return this.role === "cluster manager";
  }

  get isAgent() {
    // return true;
    return this.role === "agent";
  }

  get isExpert() {
    // return true;
    return this.role === "expert";
  }

  get isModerator() {
    return this.role === "moderator";
  }

  get isFacilityManager() {
    return this.role === "facility manager";
  }

  get isRegulatorRepresentative() {
    return this.role === "regulator representative";
  }

  get isFacility() {
    return this.role === "facility";
  }

  get isPharmacist() {
    return this.role === "pharmacist";
  }

  get isLegalCompAdmin() {
    return this.role === "compliance_legal_admin";
  }

  get isTop3() {
    return this.isAdmin || this.isRegionalRepresentatives || this.isSupervisor;
  }

  get isMedicalDepartment() {
    return this.role === "medical case department";
  }

  get userModules() {
    const user = getUserData();

    // hajj insspection form for every one
    if (Array.isArray(user?.modules)) {
      [
        "Medical-Mission",
        "Hajj-PHC",
        "Pharmacies-Audit",
        "Hajj-General-Hospital",
        "Hajj-General-Complex",
        "Hajj-Private-Hospital",
        "Forms",
      ].forEach((slug) => {
        user.modules.push({ slug });
      });
    }

    return get(user, "modules", []).map(
      (m) => m.slug?.toLowerCase() || m.name?.toLowerCase()
    );
  }

  get availableModules() {
    return [...this.userModules, ...this.unprotectedModule];
  }

  get roles() {
    return get(this.user, "type")?.toLowerCase() == "sof admin"
      ? sofRoles
      : [ ...roles, ...sofRoles,];
  }

  get regions() {
    const user = getUserData();
    return get(user, "region", []).map((m) => {
      return {
        ...m,
        label: m.name,
        value: m._id,
      };
    });
  }

  get regionNames() {
    return this.regions.map((r) => r.label);
  }

  get isSystemUser() {
    return this.user?.email === "system@efficax.com";
  }
  get slugForRoles() {
    if (ModuleService.modules) {
      return ModuleService.modules
        .filter((module) => module.roles?.includes?.(this.role))
        .map((m) => m.slug?.toLowerCase() || m.name?.toLowerCase());
    }

    return [];
  }

  get canCreate() {
    return !(
      AuthUser.isFacilityManager ||
      AuthUser.isFacility ||
      AuthUser.isHoldCo
    );
  }

  get hasViolation() {
    return this.user?.profiles?.find(
      ({ organization }) => organization?.name === "Violation"
    );
  }
  get user() {
    return getUserData();
  }
}
const AuthUser = new AuthClass();

export default AuthUser;
export { AuthClass };
