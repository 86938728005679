import { Singleton, Resources } from "../Resource";
import { SC } from "../Api/serverCall";
import startCase from "lodash/startCase";
import get from "lodash/get";
import moment from "moment";
class Service extends Resources {
  route = "FacilitySite";
  routes = {
    find: "/GetAllFacilitySites",
    create: "/CreateFacilitySite",
    show: "/GetFacilitySiteById",
    update: "/UpdateFacilitySite",
    delete: "/Delete",
    export: "FacilitySite/export",
  };
  isDotNet=true
  constructor() {
    super(arguments);
  }

  getHeaders(obj) {
    if (obj) {
      return [
        { title: "Unique Id", dataIndex: "uniqueId" },
        { title: "Region", dataIndex: "region" },
        { title: "Cluster", dataIndex: "cluster" },

        {
          title: startCase("name arabic"),
          dataIndex: "name_ar",
        },
        {
          title: startCase("name"),
          dataIndex: "name",
        },

        { title: startCase("city"), dataIndex: "city" },
        { title: startCase("facility_type"), dataIndex: "facility_type" },
        { title: startCase("licenseNumber"), dataIndex: "licenseNumber" },
        { title: startCase("Sector"), dataIndex: "sector" },
        { title: startCase("Seha ID"), dataIndex: "seha_id" },
        { title: startCase("Organization ID"), dataIndex: "organization_id" },
        { title: startCase("longitude"), dataIndex: "longitude" },
        { title: startCase("latitude"), dataIndex: "latitude" },
        { title: startCase("version"), dataIndex: "version" },
        { title: startCase("active"), dataIndex: "active" },
        { title: "Created At", dataIndex: "created_at" },
      ];
    }

    return [];
  }

  getMaxnumber(users, number) {
    if (users?.length > number) {
      return users.length;
    }

    return number;
  }

  mapExportData(data = []) {
    try {
      data = data.map((item) => {
        item.uniqueId = item._id
        item.region = get(item, "region.name", "");
        item.cluster = get(item, "cluster.name", "");
        item.created_at = moment(item.created_at).format("DD-MM-YYYY h:mm A");
        item.facility_type = item.facilityType;
        item.sector = item.sector;
        item.seha_id = item.seha_id;
        item.organization_id = item.organization_id;
        item.module = item.module?.join?.(", ");
        item.longitude = item.longitude;
        item.latitude = item.latitude;
        item.active = item.active ? "Yes" : "No";

        return item;
      });
    } catch (error) {
      console.log("Nupco export error: ", error);
    }

    return {
      headers: this.getHeaders(data[0]),
      data,
    };
  }
}

export default Singleton(Service);
