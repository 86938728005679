import React, { Fragment, useState } from "react";
import {
  Row,
  Button,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
} from "reactstrap";
import get from "lodash/get";
import startCase from "lodash/startCase";
import moment from "moment";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { observer } from "mobx-react";
import styled from "styled-components";
import List from "../../wasfaty/components/listing/List";
import { IntlService, ModuleService } from "../../wasfaty/services";
import ListHeader from "../../../@core/components/ListHeader/ListHeader";
import { moreVerticle } from "./components/scheduleTask/SVGSheduleAsset";
import HistoryModal from "./components/scheduleTask/HistoryModal";
import OptionDropDown from "./components/scheduleTask/OptionDropDown";
import AuthUser from "../../wasfaty/services/AuthService";
import RejectModel from "../../../@core/components/RejectModal";
import SOFAssessmentTaskModel from "../../wasfaty/Models/SOFAssessmentTaskModel";

import "./styles.scss";
import SOFAssessmentTaskService from "../../wasfaty/services/SOFAssessmentTaskService";

const StyledPhoneCallIcon = styled.div`
  display: inline-block;
  cursor: pointer;
  transition: transform 0.2s;

  &:hover {
    transform: scale(1.2);
    color: #11a945;
  }
`;

const PENDING = "pending";
const INITIATED = "initiated";
const CANCELLED = "cancelled";
const SUBMITTED = "submitted";
const APPROVED = "approved";
const CLUSTER_APPROVED = "cluster approved";
const FM_APPROVED = "fm approved";
const REJECTED = "rejected";
const REOPEN = "reopen";
const REOPENA_PPROVED = "reopen-approved";
const REOPEN_REJECTED = "reopen-rejected";

const ACCEPT = "accepted";

function ScheduleSOFSelfAssessmentTasks() {
  const navigate = useNavigate();
  const [items, setItems] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [modal, setModal] = useState(false);
  const [taskSelected, setTaskSelected] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [rejectModel, setRejectModel] = useState(false);
  const [actionTask, setActionTask] = useState(null);
  const [inputValue, setInputValue] = useState("");

  const toggleRejectModel = () => {
    setRejectModel((prev) => !prev);
  };

  const [pagination, setpagination] = useState({
    page: 0,
    pageCount: 0,
    perPage: 10,
    to: 0,
    totalPages: 0,
  });
  const [filter, setFilter] = useState({
    search: "",
    fromDate: "",
    toDate: "",
  });

  const getAllItems = (query) => {
    setIsLoading(true);
    setLoading(true);

    const params = {
      page: (query?.selected || 0) + 1,
      per_page: query?.perPage || 10,
      ...filter,
    };

    SOFAssessmentTaskService.find(params)
      .then((res) => {
        if (res?.data?.data) {
          setItems(
            res.data.data.data.map((task) => new SOFAssessmentTaskModel(task))
          );

          const _pagination = res.data.data;
          const page = _pagination.current_page;
          const perPage = _pagination.per_page;
          const totalPages = _pagination.total;
          const pageCount = Math.ceil(totalPages / perPage);
          const to = _pagination.to;

          setpagination({
            page,
            pageCount,
            perPage,
            totalPages,
            to,
          });
        }
        setLoading(false);
        setIsLoading(false);
      })
      .catch((e) => {
        console.log("🚀 ~ getAllItems ~ err:", e);
        setLoading(false);
        setIsLoading(false);
      });
  };

  const basicColumns = [
    {
      name: "Task",
      cell: (row) => <span>{get(row, "reportNumber", "N/A")}</span>,
    },
    {
      name: "Region",
      cell: (row) => <span>{IntlService.m(get(row, "region.name"))}</span>,
    },
    {
      name: "Site",
      cell: (row) => (
        <span>{get(row, "site.name_ar", get(row, "site.name"))}</span>
      ),
    },
    {
      name: "License #",
      cell: ({ site }) => <span>{site?.licenseNumber || "N/A"}</span>,
    },
    {
      name: "Form Name",
      cell: ({ form_name, meta_data }) => (
        <span>
          {form_name
            ? IntlService.m(ModuleService.getNameBySlug(form_name)) || form_name
            : meta_data?.name || "N/A"}
        </span>
      ),
    },
    {
      name: "Assigned To",
      cell: (row) => <span>{get(row, "user.name", "N/A")}</span>,
      omit: AuthUser.isAgent,
    },
    {
      name: "Assigned By",
      cell: (row) => <span>{get(row, "creator.name", "N/A")}</span>,
    },
    {
      name: "Start Date",
      cell: (row) => (
        <span>
          {row.start_date ? moment(row.start_date).format("DD-MM-YYYY") : "N/A"}{" "}
          <strong>
            {row.start_date ? moment(row.start_date).format("h:mm A") : "N/A"}
          </strong>
        </span>
      ),
    },
    {
      name: "Due Date",
      cell: (row) => (
        <span>
          {row.end_date ? moment(row.end_date).format("DD-MM-YYYY") : "N/A"}{" "}
          <strong>
            {row.end_date ? moment(row.end_date).format("h:mm A") : "N/A"}
          </strong>
        </span>
      ),
    },
    {
      name: "Status",
      width: "130px",
      cell: (row) => (
        <span>
          <span
            className={`badge ${row.status || "text-black"}`}
            style={{ fontWeight: 600 }}
          >
            {startCase(row.status) || "N/A"}
          </span>
        </span>
      ),
    },
    {
      name: "Action",
      sortable: false,
      center: true,
      cell: (row) => (
        <div
          onClick={(e) => {
            setSelectedRow({
              task: row,
              clientX: e.clientX + 30,
              clientY: e.clientY + 70,
            });
          }}
        >
          {moreVerticle}
        </div>
      ),
    },
  ];

  const updateStatus = (status, _id, message) => {
    SOFAssessmentTaskService.updateStatus(status, _id, message).then((res) => {
      if (res?.status == 200) {
        toast.success(res?.data?.data);
        getAllItems();
      } else {
        toast.error(res.response.data.data);
      }
    });
  };

  const ApproveReject = (status, _id, message) => {
    SOFAssessmentTaskService.approveReject(status, _id, message).then((res) => {
      if (res?.status == 200) {
        toast.success(res?.data?.message);
        getAllItems();
      } else {
        toast.error(res.response.data.message);
      }
    });
  };
  const toggle = () => setModal(!modal);

  const getOptions = () => {
    let dropdownOptions = [];
    if (!selectedRow) return [];

    const { task } = selectedRow;
    console.log(
      "🚀 ~ file: ScheduleSOFSelfAssessmentTasks.js ~ line 268 ~ getOptions ~ task",
      task
    );

    const {
      is_reopened,
      showEdit,
      is_pending,
      is_initiated,
      is_rejected,
      is_reopen_accepted,
      is_reopen_rejected,
      meta_data,
      form_name,
      _id,
      is_submitted,
      is_fm_approved,
      is_cluster_approved,
      is_approved,
      canSeeApprove,
      history,
    } = task;

    const createOption = (title, onClick, omit = false) => ({
      title,
      onClick,
      omit,
    });

    const approvedOption = createOption("Approve", () => {
      // const status = is_reopened ? REOPENA_PPROVED : APPROVED;
      let status;

      if (is_cluster_approved) {
        status = APPROVED;
      } else if (is_fm_approved) {
        status = CLUSTER_APPROVED;
      } else if (is_submitted) {
        status = FM_APPROVED;
      } else if (is_reopened) {
        status = REOPENA_PPROVED;
      } else {
        status = APPROVED;
      }

      ApproveReject(status, _id);
    });

    const rejectOption = createOption("Reject", () => {
      setActionTask(task);
      toggleRejectModel();
    });

    const startOrEditOption = createOption(showEdit ? "Edit" : "Start", () => {
      if (
        is_pending ||
        is_initiated ||
        is_reopen_accepted ||
        is_rejected ||
        is_submitted
      ) {
        const navigateToNextPage = () => {
          const path = meta_data?.isDynamicForm
            ? `/${meta_data.form_id}/Form-Builder/Add/${_id}?module=SOF_ASSESSMENT`
            : `/${form_name}/Add/${_id}?module=SOF_ASSESSMENT`;
          navigate(path);
        };
        if (showEdit) {
          navigateToNextPage();
        } else {
          SOFAssessmentTaskService.updateStatus(INITIATED, _id).then((res) => {
            if (res?.status == 200) {
              if (!res.data.status) {
                toast.error(res?.data?.data);
              } else {
                toast.success(res?.data?.data);
                getAllItems();
                navigateToNextPage();
              }
            } else {
              toast.error(res.response.data.data);
            }
          });
        }
      }
    });

    const detailsOption = createOption(
      "Details",
      () => {
        if (is_pending || is_reopen_accepted) {
          updateStatus(INITIATED, _id);
        }
        const path = meta_data
          ? `/${meta_data.form_id}/Form-Builder/${_id}/task?module=SOF_ASSESSMENT`
          : `/${form_name}/Task/${_id}?module=SOF_ASSESSMENT`;
        navigate(path);
      },
      !task?.data
    );

    const historyOption = createOption("History", () => {
      setTaskSelected(history);
      toggle();
    });

    const getFacilityOptions = () => {
      if (is_pending || is_initiated) return [startOrEditOption, historyOption];
      if (is_rejected) return [startOrEditOption, detailsOption, historyOption];
      if (is_approved || is_reopened || is_reopen_rejected)
        return [detailsOption, historyOption];

      return [detailsOption, historyOption];
    };

    const getFacilityManagerOptions = () => {
      if (is_submitted)
        return [approvedOption, rejectOption, detailsOption, historyOption];
      // if (is_approved) return [reopenOption, detailsOption, historyOption];
      return [historyOption, detailsOption];
    };

    const getClusterManagerOptions = () =>
      canSeeApprove
        ? [approvedOption, rejectOption, detailsOption, historyOption]
        : [detailsOption, historyOption];

    switch (true) {
      case AuthUser.isSOFFacility:
        dropdownOptions = getFacilityOptions();
        break;
      case AuthUser.isFacilityManager:
        dropdownOptions = getFacilityManagerOptions();
        break;
      case AuthUser.isSOFCuster:
        dropdownOptions = getClusterManagerOptions();
        break;
      case AuthUser.isSOFHoldCo:
        dropdownOptions = getClusterManagerOptions();
        break;

      default:
        dropdownOptions = [];
    }

    return dropdownOptions;
  };

  const SubmitAction = () => {
    const status = REJECTED;
    SOFAssessmentTaskService.approveReject(
      status,
      actionTask?._id,
      inputValue
    ).then((res) => {
      if (res.status == 200) {
        toast.success(res?.data?.message);
        getAllItems();
      } else {
        toast.error(res.response.data.message);
      }
      setInputValue("");
      setActionTask(null);
    });
  };

  function handleInputChange(event) {
    setInputValue(event.target.value);
  }

  return (
    <Fragment>
      <RejectModel
        inputValue={inputValue}
        rejectModel={rejectModel}
        SubmitAction={SubmitAction}
        onChange={handleInputChange}
        toggleRejectModel={toggleRejectModel}
      />
      {!!selectedRow && (
        <OptionDropDown
          key={selectedRow.task._id}
          onClose={() => setSelectedRow(null)}
          row={selectedRow}
          options={getOptions()}
        />
      )}
      <HistoryModal modal={modal} toggle={toggle} taskSelected={taskSelected} />
      <ListHeader
        pagination={pagination}
        filter={filter}
        setFilter={setFilter}
        getData={getAllItems}
        loading={loading}
        totalHeader="Available Tasks"
      />
      <Row style={{ overFlow: "auto" }} striped>
        <Col sm="12">
          <List
            basicColumns={basicColumns}
            Mock={items}
            pagination={pagination}
            handlePageChange={getAllItems}
            isLoading={isLoading}
            expandable={true}
            expandableRows
          />
        </Col>
      </Row>
    </Fragment>
  );
}

export default ScheduleSOFSelfAssessmentTasks;
