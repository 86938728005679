
const config = {
  bucketName: "efficax-obligation",
  endpoint: "https://micro-helper.ascend.com.sa/minio/upload",
};

class Service {
  constructor() {}
  async uploadFile(file, callBackS3, progressCB) {
    if (file) {
      const formData = new FormData();
      formData.append("file", file); // Change 'file' to 'files'
      formData.append("project", "efficax-obligation");

      let Key = `${Date.now()}_${file?.name}`;
      try {
        let res = await fetch(config.endpoint, {
          method: "POST",
          body: formData,
        }).then((res) => res.json());

        res = {
          Location: res.paths,
          key: Key,
        };
        progressCB(100);
        callBackS3(res);
      } catch (error) {
      }
    } else {
      alert("No file found");
    }
  }
}

const S3Service = new Service();

export default S3Service;
