import { Resources } from "./Resource";
import { SC } from "../api/serverCall";
import { authHeader } from "../api/authHeader";

class Service extends Resources {
  route = "FormSubmission";
  routes = {
    find: "",
    create: "/create",
    show: "/show",
    update: "/update",
    delete: "/delete",
    all: "/all",
  };
  ranking = {};

  constructor() {
    super(arguments);
  }

  reject(id) {
    let data = {
      id,
    };
    return SC.postCall({
      url: "FormSubmission/rejected",
      data,
    });
  }

  approved(id) {
    let data = {
      id,
    };
    return SC.postCall({
      url: "FormSubmission/approved",
      data,
    });
  }
  async all(params = {}) {
    return SC.getCall({
      url: this.route + this.routes.all,
      params,
    });
  }

  async manualExcel(id) {
    return fetch(process.env.REACT_APP_BASEURL + this.route + `/manual-excel/${id}`, {
      headers: authHeader(),
      method: "GET",
    }).then((response) => {
      return response.blob();
    });
  }
}

const FormSubmissionService = new Service();
export default FormSubmissionService;
